<template>
  <div class="container">
    <!-- 占位div -->
    <div :style="{height: $store.state.changeHeight + 'px'}"></div>
    <!-- 订单编号 -->
    <div class="main" style="padding-bottom:15px;text-align: center;">
      <h1 class="title">Your order is now completed.</h1>
      <!-- <h1 v-else class="title">Your order is generating.</h1> -->
      <el-row :gutter="20">
        <el-col :span="24" class="font16" style="">
          <h5 class="order_label">Order Confirmation Number: {{ tempId }}</h5>
          <p class="mt15">Your ticket order confirmation will be sent to:</p>
          <p class="blueFont email">{{ peopleInfo.email }}</p>
          <p>If you can not find your confirmation email in your inbox, please kindly check in your spam or junk mail section. Thank you.</p>
        </el-col>
      </el-row>
    </div>
    <!-- 下载码列表 -->
    <div class="main">
      <el-card class="electronic-card">
        <h1 class="head-txt">Order Information</h1>
        <div v-if="ticketsOnedayAndTwodayArr.length > 0">
          <div class="base">TICKETS</div>
          <div class="tickets_list">
            <el-row v-for="(item, index) in ticketsOnedayAndTwodayArr" :key="index" type="flex" align="middle" class="ticket_item">
              <el-col v-if="[1, 2, 5, 7, 9].includes(item.ticketKind)" class="font14 ticket_item-left" :span="12">
                <div>
                  <p class="">
                    <span class="ticket-name">{{ getTicketTypeName(item.ticketKind) }}</span>
                  </p>
                  <p>
                    {{item.num}}
                    <span v-if="item.ticketType === 1 && item.ticketKind!==7">{{item.num>1?'Adults':'Adult'}}</span>
                    <span v-if="item.ticketType === 2 && item.ticketKind!==7">{{item.num>1?'Children':'Child'}}</span>
                    <span v-if="item.ticketType === 3 && item.ticketKind!==7">{{item.num>1?'Seniors':'Senior'}}</span>
                    <span v-if="item.ticketKind===7"> All Ages</span>
                    <!-- {{ formatDate(item.travelDate) }} -->
                  </p>
                  <p> {{ formatDate(item.travelDate) }}</p>
                </div>
                <div v-if="item.outerOrderItemId" class="OrderId">Order Number: {{ item.outerOrderItemId }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="expressTicket.length > 0">
          <div class="base">EXPRESS PASSES</div>
          <div class="tickets_list">
            <el-row v-for="(item, index) in expressTicket" :key="index" type="flex" align="middle" class="ticket_item">
              <el-col class="ticket_item-left" :sm="13" :span="18">
                <p class="font_weight_700 font20">
                  <span class="ticket-name" v-if="item.officialName">{{ item.officialName }}</span>
                  <span class="ticket-name" v-else>{{ conversionTicketName(item.productId) }}</span>
                </p>
                <div class="font14">
                  <p>{{ item.num }} All Ages</p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                  <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                </div>
                <p class="font14" v-if="item.outerOrderItemId">Order Number: {{ item.outerOrderItemId }}</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- EXTRAS -->
        <div v-if="braceletTicket.length > 0">
          <div class="base">EXTRAS</div>
          <div class="tickets_list">
            <el-row v-for="(item, index) in braceletTicket" :key="index" type="flex" align="middle" class="ticket_item">
              <el-col class="ticket_item-left" :sm="13" :span="18">
                <p class="font_weight_700 font20">
                  <span class="ticket-name" v-if="item.officialName">{{ item.officialName }}</span>
                  <span class="ticket-name" v-else>{{ conversionTicketName(item.productId) }}</span>
                </p>
                <div class="font14">
                  <p>{{ item.num }} All Ages</p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                  <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                </div>
                <p class="font14" v-if="item.outerOrderItemId">Order Number: {{ item.outerOrderItemId }}</p>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-card>
    </div>
    <!-- download electronic  end -->
    <!-- 订单摘要 -->
    <div class="main">
      <el-card class="summary-card">
        <h1 class="head-txt">Your Order Summary</h1>
        <!-- <el-row class="font20 mt15" type="flex" justify="space-between">
          <el-col>
            <p class="boldFont">Mr/Mrs {{ peopleInfo.LastName }}</p>
            <p v-if="payTime" class="pay-time">Payment Time: {{ formatDate(payTime.substr(0, 10)) }} {{ payTime.substr(11, 5) }}</p>
          </el-col>
          <el-col class="total" style="text-align:right;">¥{{ total || 0 | abs }}</el-col>
        </el-row> -->
        <div v-if="ticketsOnedayAndTwodayArr.length > 0">
          <div class="base">TICKETS</div>
          <div class="tickets_list">
            <div v-for="(item, index) in ticketsOnedayAndTwodayArr" :key="index" class="ticket_item flex-space-between">
              <div>
                <p class="ticket-name">{{ getTicketTypeName(item.ticketKind )}}</p>
                <p class="font14">
                  {{item.num}}
                  <span v-if="item.ticketType === 1 && item.ticketKind!==7">{{item.num>1?'Adults':'Adult'}}</span>
                  <span v-if="item.ticketType === 2 && item.ticketKind!==7">{{item.num>1?'Children':'Child'}}</span>
                  <span v-if="item.ticketType === 3 && item.ticketKind!==7">{{item.num>1?'Seniors':'Senior'}}</span>
                  <span v-if="item.ticketKind===7"> All Ages</span>
                </p>
                <p class="font14">{{ formatDate(item.travelDate) }}</p>
              </div>
              <div style="text-align:right;">
                <p class="total">¥{{  item.num * item.price  || 0 | abs}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Express Pass -->
        <div v-if="expressTicket.length > 0">
          <div class="base">EXPRESS PASSES</div>
          <div class="tickets_list">
            <div v-for="(item, index) in expressTicket" :key="index" class="ticket_item flex-space-between">
              <div>
                <span class="ticket-name" v-if="item.officialName">{{ item.officialName }}</span>
                <span class="ticket-name" v-else>{{ conversionTicketName(item.productId) }}</span>
                <div class="font14">
                  <p>{{ item.num }} All Ages</p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                  <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                </div>
              </div>
              <div style="text-align:right;">
                <p class="total">¥{{ item.num * item.price || 0 | abs}}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- EXTRAS -->
        <div v-if="braceletTicket.length > 0">
          <div class="base">EXTRAS</div>
          <div class="tickets_list">
            <div v-for="(item, index) in braceletTicket" :key="index" class="ticket_item flex-space-between">
              <div>
                <span class="ticket-name" v-if="item.officialName">{{ item.officialName }}</span>
                <span class="ticket-name" v-else>{{ conversionTicketName(item.productId) }}</span>
                <div class="font14">
                  <p>{{ item.num }} All Ages</p>
                  <p>{{ formatDate(item.travelDate) }}</p>
                  <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                </div>
              </div>
              <div style="text-align:right;">
                <p class="total">¥{{ item.num * item.price || 0 | abs}}</p>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util'
import { mixin2 } from '@/mixin/index'
import { getCode } from '@/api/index'
import { dateTimeFormatByZone } from '@/utils/date.js'
import axios from 'axios'


export default {
  mixins: [mixin2],
  data() {
    return {
      orderInfo: {},
      tickets: [], //所有票的数组
      peopleInfo: {},
      expressTicket: [], // ticketsCopy里的快速票
      braceletTicket: [], // 手环产品列表
      outerOrderId: JSON.parse(localStorage.getItem('outerOrderId')),
      timer: '',
      payStatus: '', // 支付状态
      orderUsjCodeList: [], // 码列表
      payTime: '2021-03-19T02:56:17Z', //支付时间
      loading: false,
      timeZoneList: [], // 时区列表
      onTimeZone: 1, // 时区值
      zone: '',
      tempId: '',
      total: 0,
      ticketsOnedayAndTwodayArr: [] //一日票和二日票和1.5日数组
    }
  },

  async created() {
    // window.open(window.location.href)
    // 如果是通过stripe支付跳转过来的会带x参数
  
    if (this.$route.query.x) {
      this.outerOrderId = this.$route.query.x
      await this.getTimezones() // 获取时区
      await this.loadData()
      await this.dataHandle()
    }

    await this.getOrder()
    this.getSubtotal()
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 获取数据
    async loadData() {
      if (this.outerOrderId) {
        await getCode(this.outerOrderId).then(res => {
          if (res.data.code === 0) {
            res.data.data.orderUsjItemList.map(item => {
              item.travelDate = dateTimeFormatByZone(item.travelTime, this.zone, 'YYYY-MM-DD')
            })
            this.peopleInfo.email = res.data.data.email
            this.tickets = res.data.data.orderUsjItemList
            this.tickets.forEach(item => {
              if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
                this.ticketsOnedayAndTwodayArr.push(item)
              }
            })
            this.tickets.map((item, index) => {
              if (item.ticketKind === 3) {
                this.expressTicket.push(item)
              }
            })
            this.tickets.map((item, index) => {
              if (item.ticketKind === 8) {
                this.braceletTicket.push(item)
              }
            })
            this.payStatus = res.data.data.payStatus
            this.tempId = res.data.data.outerOrderId
            this.payTime = res.data.data.payTime
            this.onTimeZone = res.data.data.onTimeZone
            localStorage.removeItem('orderInfo')
            localStorage.removeItem('peopleInfo')
            localStorage.removeItem('endAddCartDate') 
            // this.setTicketCode()
          }
          
        })
        this.payTime = dateTimeFormatByZone(this.payTime, this.zone, 'YYYY-MM-DD HH:mm')
      }
    },
    // 定时获取票的码
    getOrder() {
      if (this.outerOrderId) {
        this.loading = true
        const _that = this
        this.timer = setInterval(function () {
          _that.getOrderDetail()
        }, 15 * 1000)
      }
    },
    // 获取订单详情
    getOrderDetail() {
      if (this.outerOrderId) {
        getCode(this.outerOrderId).then(res => {
          if (res.data.code === 0) {
            this.orderUsjCodeList = res.data.data.orderUsjCodeList
            this.payStatus = res.data.data.payStatus
            this.setTicketCode()
          }
          if (res.data.data.orderUsjCodeList.length > 0) {
         
            this.loading = false
            clearInterval(this.timer)
            this.$forceUpdate()
          }
        })
      }
    },
    // 给票加上码url
    setTicketCode() {
      if (this.tickets.length > 0) {
        this.tickets.map(item => {
          item.codeUrl = []
          for (let i = 0; i < this.orderUsjCodeList.length; i++) {
            if (item.outerOrderItemId === this.orderUsjCodeList[i].outerOrderItemId) {
              let url = this.orderUsjCodeList[i].codeUrl
              // let AfterUrl =url.replace(/\+/g,'%2B')

              item.codeUrl.push(url)
            }
          }
        })
      }
      if (this.expressTicket.length > 0) {
        this.expressTicket.map(item => {
          item.codeUrl = []
          for (let i = 0; i < this.orderUsjCodeList.length; i++) {
            if (item.outerOrderItemId === this.orderUsjCodeList[i].outerOrderItemId) {
              let url = this.orderUsjCodeList[i].codeUrl
              // let AfterUrl =url.replace(/\+/g,'%2B')

              item.codeUrl.push(url)
            }
          }
        })
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.map(item => {
          item.codeUrl = []
          for (let i = 0; i < this.orderUsjCodeList.length; i++) {
            if (item.outerOrderItemId === this.orderUsjCodeList[i].outerOrderItemId) {
              let url = this.orderUsjCodeList[i].codeUrl
              // let AfterUrl =url.replace(/\+/g,'%2B')

              item.codeUrl.push(url)
            }
          }
        })
      }
    },
    splitBatName(str) {
      if(str===undefined) return
      if(str.length===0) return
      if (str !== undefined) {
        let strArr = str.split(')')
        for (let i = 0; i < strArr.length; i++) {
          strArr[i] += ')'
        }
        return strArr.splice(0, strArr.length - 1)
      }
    },
    // 计算总价
    getSubtotal() {
      let total = 0
      if (this.ticketsOnedayAndTwodayArr.length > 0) {
        this.ticketsOnedayAndTwodayArr.forEach(item => {
          total += item.num * item.price
        })
      }
      if (this.expressTicket.length > 0) {
        this.expressTicket.forEach(item => {
          total += item.num * item.price
        })
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.forEach(item => {
          total += item.num * item.price
        })
      }
      this.total = total
      // return total   
    },
    // 获取支付时间
    getPayTime() {
      if (localStorage.getItem('payTime')) {
        let dateArr = localStorage.getItem('payTime').split(' ')
        return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]} ${dateArr[4]}`
      }
    },
    // 获取时区列表
    async getTimezones() {
      await axios
        .get('https://nta.tripodeck.com/api/ops/app/timezones')
        .then(res => {
          if (res.data.code === 0) {
            this.timeZoneList = res.data.data
          } else {
            this.timeZoneList = []
          }
        })
      this.getTimeZone()
    },
    // 获取单个时区的zone
    getTimeZone() {
      if (this.timeZoneList.length > 0) {
        this.timeZoneList.map(item => {
          if (item.value === this.onTimeZone) {
            this.zone = item.zone
          }
        })
      }
    },
    getTicketTypeName(value) {
      const options = {
        1: '1 Day Studio Pass',
        2: '2 Day Studio Pass',
        5: '1.5 Day Studio Pass',
        7: "NO LIMIT! Countdown 2024 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
      }
      return options[value]
    },
    formatDate(val) {
      return formatDate(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    width: 1200px;
    margin: 0 auto;
  }
}
.title {
  color: #333;
  font-size: 52px;
  font-weight: 600;
  margin-top: 25px;
}
.order_label {
  font-weight: 900;
  margin-top: 55px;
  font-size: 20px;
}
.electronic-card {
  margin-top: 32px;
}
.tickets_list {
  padding-right: 15px;
  .ticket_item {
    padding: 10px 0 10px 0;
    margin-left: 15px;
    &:not(:last-child) {
      border-bottom: 1px dashed;
    }
    .download-btn-col {
      text-align: right;
      .process_btn:hover {
        background-color: #c8c9cc;
      }
    }
    // 订单列表每个订单的样式start
    // .ticket_info{
    //   display: flex;
    //   .OrderId{
    //     font-size: 14px;
    //     margin-left: 30px;
    //     display: flex;
    //     align-items: center;
    //   }
    //   p:first-child{
    //     display: -webkit-box;
    //     display: -moz-box;
    //     display: -ms-flexbox;
    //     display: -webkit-flex;
    //     display: flex;
    //     -webkit-box-align: center;
    //     -ms-flex-align: center;
    //     -webkit-align-items: center;
    //     align-items: center;
    //   }
    //   .ticket-name::before{
    //     content: '';
    //     display: inline-block;
    //     position: absolute;
    //     left: -15px;
    //     top: 22px;
    //     width: 5px;
    //     height: 5px;
    //     background-color: black;
    //     border-radius: 2.5px;
    //   }
    // }
    // 订单列表每个订单的样式end
    .download_btn {
      background-color: #036ce2;
      border-radius: 6px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      width: 81px;
      text-align: center;
      margin-bottom: 10px;
      a {
        color: white;
      }
    }
  }
}
.summary-card {
  margin-top: 63px;
}
.pay-time {
  font-weight: 500;
  margin-bottom: 10px;
}
::v-deep .el-card {
  .head-txt {
    font-weight: 500;
    font-size: 32px;
    margin-top: 8px;
  }
}
.border-bottom {
  border-bottom: 1px solid #1214651f;
}
.base {
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  padding-left: 15px;
  font-weight: 700;
  margin-top: 20px;
  background-color: #e0f0ff;
}
.email {
  font-size: 20px;
  font-weight: 500;
}
::v-deep .el-card {
  border-radius: 12px;
}
.total {
  font-weight: 700;
  font-size: 20px;
}
.ticket-name {
  font-weight: 700;
  font-size: 20px;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 20px;
    font-weight: 900;
  }
  .order_label {
    margin-top: 10px;
    font-size: 18px;
    color: #333;
  }
  .el-card .head-txt {
    font-size: 20px;
  }
  .base {
    font-size: 18px;
    font-weight: 500;
  }
  .pay-time {
    font-size: 16px;
  }
  .total {
    font-size: 16px;
  }
  .ticket-name {
    font-size: 16px;
  }
  .tickets_list {
    padding: 0;
    .ticket_item {
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
      .ticket_item-left {
        width: 100%;
      }
      .download-btn-col {
        width: 100%;
        display: flex;
        // justify-content:space-around;
        flex-wrap: wrap;
        // text-align: left;
      }
    }
  }
  .el-button + .el-button:nth-child(4),
  .el-button + .el-button:nth-child(7),
  .el-button + .el-button:nth-child(10) {
    margin: 0;
  }
}
::v-deep .el-loading-spinner .circular {
  position: relative;
  top: 9px;
  height: 25px;
  width: 25px;
}
::v-deep .el-loading-mask {
  z-index: 0;
}
.process_btn {
  background: #c8c9cc;
  border: 0;
}
</style>

